// require('./bootstrap');
// window._ = require('lodash');
window.$ = require('jquery');
window.jQuery = window.$;
window.Toastify = require('toastify-js');
require('slick-carousel');

$(function () {
    const body = $('body');

    // --- Sticky Header
    const header = $('.header');
    const width = window.innerWidth;

    const toggleHeaderStickyClass = function () {
        if (width >= 992) {
            $(this).scrollTop() ? header.addClass('sticky') : header.removeClass('sticky');
        }
    };

    toggleHeaderStickyClass();

    $(window).on('scroll', toggleHeaderStickyClass);

    // --- Modal window
    body.on('click', '[data-modal-show]', function (e) {
        e.preventDefault();
        body.addClass('no-scroll');
        $('.modal').fadeIn('fast');
    });

    // --- Modal window -- close
    body.on('click', '[data-modal-hide]', function () {
        body.removeClass('no-scroll');
        $('.modal').fadeOut('fast');
    });

    // --- Modal window -- submit form
    $('#contact-us').on('submit', '.form', function (e) {
        e.preventDefault();
        // TODO
    });

    // --- Navigation button -- toggle on click
    body.on('click', '.header .menu-btn', function () {
        var $sidebar = body.find('.sidebar');

        $(this).toggleClass('active');
        body.toggleClass('no-scroll');

        $sidebar &&
        $sidebar.toggleClass('active') &&
        $sidebar.find(' + div').toggleClass('no-scroll');
    });

    // --- Slick slider on homepage
    if (typeof $.fn.slick !== 'undefined') {
        $('.slick').slick({
            infinite: true,
            dots: true,
            arrows: false,
            fade: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            asNavFor: '.slick-reviews',
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        dots: false,
                    }
                },
            ],
        });

        $('.slick-reviews').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            asNavFor: '.slick',
        });

        $('.recent-plans').slick({
            mobileFirst: true,
            infinite: true,
            dots: false,
            arrows: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 1280,
                    settings: {
                        slidesToShow: 4,
                    },
                },
            ],
        });
    }

    // --- Toastify
    if (window.notifications && window.notifications.length > 0) {
        window.notifications.forEach(notification => notification && Toastify({
            text: notification.text,
            className: notification.type,
            duration: 3000,
        }).showToast());
    }
});

window.showToast = function (obj) {
    Toastify({
        text: obj.text,
        className: obj.type || 'info',
        duration: 3000,
    }).showToast();
};
